import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import Text from 'ui/Text'
import WithdrawBody from 'features/Withdraw/WithdrawBody'
import WithdrawDeposit from 'features/Withdraw/WithdrawDeposit'
import Success from 'features/Withdraw/Success'
import {
    getResetBonusesStatus,
    setBonusList,
    getBonusList,
} from 'common/Services/BonusService'
import UserService from 'services/UserService'
import { BACKGROUNDS, COLORS } from 'themes'
import Loader from 'ui/Loader'
import getTexts from 'utils/localization'
import { getUserAreaUrl } from '@/routers/helper'
import { formatDenmarkCurrent } from 'utils/ui'
import GamesService from 'services/GamesService'

const t = getTexts()

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    min-height: 100%;
`
const BalanceWrapper = styled.div`
    border-bottom: 1px solid ${COLORS.grey200};
    padding: 15px;
    margin: 0 15px;
`

const BalanceValue = styled.p`
    color: ${COLORS.white};
    padding: 0;
    margin: 0;
    font-size: 35px;
    line-height: 35px;
`

const Withdraw = ({ close, actions }) => {
    const [loading, setLoading] = useState(true)

    const [userBalance, setUserBalance] = useState(0)
    const [userStatus, setUserStatus] = useState(0)

    const [bonuses, setBonuses] = useState([])
    const [gameId, setGameId] = useState(null)
    const [totalBonus, setTotalBonus] = useState(0)
    const [rollover, setRollover] = useState(0)
    const [successScreen, setSuccessScreen] = useState(false)

    useEffect(() => {
        setBonusList()

        const bonusSub = getBonusList().subscribe((bonusInfo) => {
            setGameId(bonusInfo.gameId)
            setBonuses(bonusInfo ? bonusInfo.bonuses : [])
            setTotalBonus(bonusInfo ? bonusInfo.totalBonus : 0)
            setRollover(bonusInfo ? bonusInfo.rollover : 0)

            if (bonusInfo) {
                setLoading(false)
            }
        })

        return () => {
            bonusSub.unsubscribe()
        }
    }, [])

    useEffect(() => {
        UserService.getUserLoggedStatus()
        const userSubscription = UserService.getUser().subscribe((data) => {
            setUserStatus(data.status)
            setUserBalance(data.balance)
        })
        return () => {
            userSubscription.unsubscribe()
        }
    }, [])

    useEffect(() => {
        const bonusStatus = getResetBonusesStatus().subscribe((resetStatus) => {
            if (resetStatus) {
                setLoading(true)
            }
        })

        return () => {
            bonusStatus.unsubscribe()
        }
    }, [])

    const BalanceContainer = () => {
        return (
            <BalanceWrapper>
                <Text grey padding="0">
                    {t.userArea.withdraw.balance}
                </Text>
                <BalanceValue>
                    {formatDenmarkCurrent(userBalance || 0)} {t.currency}
                </BalanceValue>
            </BalanceWrapper>
        )
    }

    return !successScreen ? (
        <Wrapper>
            <ModalTopBar
                modalName="WITHDRAW"
                title={t.userArea.withdraw.title}
            />
            {loading ? (
                <Loader margin="10px auto" color={BACKGROUNDS.grey} />
            ) : totalBonus ? (
                <Fragment>
                    <BalanceContainer />
                    <WithdrawDeposit
                        balance={userBalance}
                        totalBonus={totalBonus}
                        rollover={rollover}
                        gameId={gameId}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <BalanceContainer />
                    <WithdrawBody
                        balance={userBalance}
                        userStatus={userStatus}
                        setSuccessScreen={setSuccessScreen}
                    />
                </Fragment>
            )}
        </Wrapper>
    ) : (
        <Success actions={actions} close={close} />
    )
}

export default Withdraw
