import React from 'react'
import styled, { css } from 'styled-components'
import {
    openToLeft,
    closeToRight,
    openToRight,
    closeToLeft,
    openToTop,
    closeToBottom,
} from 'utils/animations'
import { useState, useLayoutEffect } from 'react'
import { ModalService } from 'services/ModalService'
import device from 'utils/mediaQueries'
import { BACKGROUNDS } from 'themes/index'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

const defaultAnimationSpeed = 400

const WithAnimation = styled.div`
    width: ${(props) => (props.width ? props.width : '375px')};
    height: 100%;
    position: absolute;
    background-color: ${(props) =>
        props.bg ? props.bg : BACKGROUNDS.secondary};
    ${(props) => (props.position === 'left' ? 'left: 0' : 'right: 0')};
    ${(props) =>
        props.animation === 'openAnimation' &&
        css`
            animation: ${detectMobile()
                    ? openToTop
                    : props.position && props.position === 'left'
                        ? openToRight
                        : openToLeft}
                ${defaultAnimationSpeed}ms ease;
        `};
    ${(props) =>
        props.animation === 'closeAnimation' &&
        css`
            animation: ${detectMobile()
                    ? closeToBottom
                    : props.position && props.position === 'left'
                        ? closeToLeft
                        : closeToRight}
                ${defaultAnimationSpeed}ms ease;
        `};

    @media screen and ${device.sm} {
        width: 100%;
    }
`

export const withAnimation = (Component, position = 'right') => ({
    animationSpeed = defaultAnimationSpeed,
    ...props
}) => {
    const [animation, setAnimation] = useState('')
    const close = (modalName) => {
        animateBackward()

        setTimeout(() => {
            modalName
                ? ModalService.closeModal(modalName)
                : ModalService.closeModal()
        }, defaultAnimationSpeed)
    }

    const animateForward = () => setAnimation('openAnimation')
    const animateBackward = () => setAnimation('closeAnimation')

    const hocProps = { close, open }

    const width = props.data.initialProps && props.data.initialProps.width
    const bg = props.data.initialProps && props.data.initialProps.bg

    useLayoutEffect(() => {
        animateForward()
    }, [])

    return (
        <WithAnimation
            animation={animation}
            position={position}
            width={width}
            bg={bg}
        >
            <Component {...hocProps} {...props}>
                {props.children}
            </Component>
        </WithAnimation>
    )
}
